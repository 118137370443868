import React from 'react';

const FeeStructure = ({data}) => {
    return (
        <section className='contact-section timing-section p-100'>
            <div className="container-fluid mx-auto">
                <div className="row justify-center mb-8">
                    <div className="col-lg-6 text-center ts-head">
                        <h2 className="text-3xl font-bold text-white uppercase">
                            Fee Structure
                        </h2>
                    </div>
                </div>

                <div className='row overflow-x-auto'>
                    <table className="w-full table-auto border-collapse max-sm:overflow-x-scroll">
                        <thead className="w-full bg-[#a88309] text-white text-lg max-md:text-sm max-sm:text-[0.5rem]">
                            <tr>
                                <th className="!border !border-gray-500 py-2 px-4 max-sm:px-1">Package</th>
                                <th className="!border !border-gray-500 py-2 px-4 max-sm:px-1">Male</th>
                                <th className="!border !border-gray-500 py-2 px-4 max-sm:px-1">Female</th>
                                <th className="!border !border-gray-500 py-2 px-4 max-sm:px-1">Couple</th>
                                <th className="!border !border-gray-500 py-2 px-4 max-sm:px-1">Kids</th>
                            </tr>
                        </thead>
                        <tbody className="w-full text-center">
                            {
                                data && data.map((fee, index) => (
                                    <tr key={index} className="!border !border-gray-500">
                                        <td className="py-2 text-base max-md:text-sm max-sm:text-text-[0.5rem] !border !border-gray-500 !border-b-[#a88309]">{fee.package}</td>
                                        <td className="py-2 text-base max-md:text-sm max-sm:text-text-[0.5rem] !border !border-gray-500 !border-b-[#a88309]">{`₹${fee?.male}`}</td>
                                        <td className="py-2 text-base max-md:text-sm max-sm:text-text-[0.5rem] !border !border-gray-500 !border-b-[#a88309]">{`₹${fee?.female}`}</td>
                                        <td className="py-2 text-base max-md:text-sm max-sm:text-text-[0.5rem] !border !border-gray-500 !border-b-[#a88309]">{`₹${fee?.couple}`}</td>
                                        <td className="py-2 text-base max-md:text-sm max-sm:text-text-[0.5rem] !border !border-gray-500 !border-b-[#a88309]">{`₹${fee?.kids}`}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default FeeStructure;
